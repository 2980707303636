import { ValueLabel } from "../types/oarTypes";

export const sortArrayByProperty = (n1: ValueLabel, n2: ValueLabel) => {
  if (n1.label > n2.label) {
    return 1;
  }
  if (n1.label < n2.label) {
    return -1;
  }
  return 0;
}