import axios from "axios";
import { journalItemsMock } from '../../mocks/journalItems';



import { UserSettings } from "../../types/oarTypes";

export const getEnabledJournalTypes = () => {
	return axios
		.get(`userSettings/journalSettings`)
		.then(function (response) {
			return (response.data);
		})
		.catch(function (error) {
			console.log(error.message);
		})
		.finally(function () {
			console.log('Successfully loaded journal types data.');
		});
};

export const fetchJournalTypes = () => {
	// Make API call to retrieve the JournalTypes
	return axios
		.get(`userSettings/journalSettings`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			console.log(error.message);
		})
		.finally(function () {
			console.log('Successfully loaded journal types data.');
		});
}

export const updateEnabledJournalTypes = (userSettings: UserSettings) => {
	axios
		.post(`userSettings/journalSettings`, userSettings)
		.then(function (response: any) {
			return (response.data);
		})
		.catch(function (error: any) {
			console.log(error.message);
		})
		.finally(function () {
			console.log('Successfully loaded journal types data.');
		});
};

export const getJournalEntriesForMonth = (year: number, monthNumber: number) => {
	return axios
		.get(`journalEntries/${year}/${monthNumber}`)
		.then(function (response) {
			return (response.data);
		})
		.catch(function (error) {
			console.log(error.message);
		})
		.finally(function () {
			console.log('Successfully loaded journal entries data.');
		});
};

export const saveJournalEntry = (date: string, id: string, value: number) => {
	return axios
		.post(`journalEntries/journalEntry`, { date, id, value })
		.then(function (response) {
			return (response.data);
		})
		.catch(function (error) {
			console.log(error.message);
		})
		.finally(function () {
			console.log('Successfully loaded journal entries data.');
		});
};
