import axios from "axios";
import React, { useState } from "react";
import { StyleSheet, Text, View, SafeAreaView, SectionList, StatusBar, FlatList, VirtualizedList, Pressable } from "react-native";
import { globalStyles } from "../../constants/GlobalStyles";
import { LifeOarGroup } from "../../types/oarTypes";
import { FontAwesome } from '@expo/vector-icons';
import { LoadingSpinnerComponent } from "../LoadingSpinner";

export function SearchResultsComponent({ navigation, listOfItems, onAdd }: any) {
  // Very important to understand that several react native component relay on what it calls a simple array.
  // This means we must pass an array of objects with id and title props only - not many other options.
  const [isLoading, setIsLoading] = useState(false);
  const getItem = (data: any[], index: number) => (
    {
      id: index.toString(),
      title: `${data[index].title}`,
      address: `${data[index].address}`,
      latitude: `${data[index].latitude}`,
      longitude: `${data[index].longitude}`,
    }
  );
  const getListItemCount = (data: any[]) => {
    if (typeof data == "undefined") {
      return 0
    }
    else return data.length
  }

  function ListIcon(props: {
    name: React.ComponentProps<typeof FontAwesome>['name'];
    color: string;
  }) {
    return <FontAwesome size={25} style={{}} {...props} />;
  }

  const Item = ({ item }) => (
    <View style={globalStyles.listItemContainer}>
      <Pressable style={{ flexDirection: "row", flexWrap: "wrap", paddingLeft: 15, flex: 1 }}
        onPress={() => navigation.navigate('LocationDetail', { 'locationId': `${item.id}` }
        )} >
        <View style={[globalStyles.container, { flexDirection: 'row', flexWrap: 'wrap' }]}>
          <ListIcon name="map-marker" color="black"></ListIcon>
          <View style={[globalStyles.container, { justifyContent: "flex-start", alignItems: "flex-start", paddingLeft: 10 }]}>
            <Text style={globalStyles.cardTitle}>{item.title}</Text>
            <Text style={globalStyles.cardSubTitle}>{item.address.toString().split(',')[0].toString().trim()}{'\n'}</Text>
            <Text style={globalStyles.cardSubTitle}>{item.address.toString().split(',')[1].toString().trim()}{', '}{item.address.toString().split(',')[2].toString().trim()}</Text>
          </View>
        </View>
      </Pressable>
      <View style={{ flexDirection: "row-reverse", paddingLeft: 10, alignItems: "center" }}>
        <Pressable onPress={() => onAdd(item)} >
          <ListIcon name="plus" color="black"></ListIcon>
        </Pressable>
      </View>
    </View>
  );
  if (isLoading) {
    return (
      <LoadingSpinnerComponent />
    );
  }
  else {
    return (
      <SafeAreaView style={{ minWidth: "90%", flex: 1 }}>
        <VirtualizedList style={globalStyles.listContainer}
          data={listOfItems}
          initialNumToRender={10}
          renderItem={({ item }) => <Item item={item} />}
          keyExtractor={item => item.id}
          getItemCount={getListItemCount}
          getItem={getItem}
        />

      </SafeAreaView>
    );
  }
}