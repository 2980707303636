import * as Location from 'expo-location';
import * as Device from 'expo-device';
import React, { useCallback, useEffect, useState } from "react";
import { Button, Platform, Pressable, Text, TouchableOpacity, View } from 'react-native';
import { globalStyles } from '../../constants/GlobalStyles';
import MapView, { Marker, PROVIDER_GOOGLE } from 'react-native-maps';
import { LoadingSpinnerComponent } from '../LoadingSpinner';
import { LocationAccuracy, LocationOptions } from 'expo-location';
import { LifeOarLocation } from "../../types/oarTypes";
import { Input } from '@rneui/base';
import axios from 'axios';
var apiKey = "AIzaSyAYFnambK-wl6YeTilHtibvyP5xyMjyBao";

import { getLocationSuggestions } from '../../services/Locations/locationsService';
import { SearchResultsComponent } from './SearchResultsComponent';

export function AddLocationComponent({ route, navigation }) {
  const [isLoading, setIsLoading] = useState(true);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [knownLocations, setKnownLocations] = useState([]);
  const [locationSuggestions, setLocationSuggestions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [region, setRegion] = useState(null);
  const [newLocationData, setNewLocationData] = useState<LifeOarLocation>();
  const [errorMsg, setErrorMsg] = useState(null);
  const groupId = route.params.groupId;

  const createNewLocation = (newLocationData: any) => {
    var newLocation: LifeOarLocation = {
      title: `${newLocationData.title}`,
      groupId: groupId,
      latitude: newLocationData.latitude,
      longitude: newLocationData.longitude,
      address: newLocationData.address
    }

    axios.post(`groups/${groupId}/locations`, newLocation)
      .then(function (response: any) {
        setNewLocationData(response.data);
        navigation.navigate('GroupDetail', { 'groupId': `${response.data.groupId}` })
      })
      .catch(function (error: any) {
        alert(error.message);
      })
      .finally(function () {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    (async () => {
      if (Platform.OS === 'android' && !Device.isDevice) {
        setErrorMsg(
          'Oops, this will not work on Snack in an Android Emulator. Try it on your device!'
        );
        return;
      }
      let { status } = await Location.requestForegroundPermissionsAsync();
      if (status !== 'granted') {
        setErrorMsg('Permission to access location was denied');
        setIsLoading(false);
        return;
      }

      let location = await Location.getCurrentPositionAsync({});
      var initialRegion = {
        "latitude": location.coords.latitude,
        "longitude": location.coords.longitude,
        "latitudeDelta": .0125,
        "longitudeDelta": .0125
      }
      setRegion(initialRegion)
      setCurrentLocation(location);
      setIsLoading(false)
    })();
  }, []);

  const handleSearch = async () => {
    setIsLoading(true);
    try {
      getLocationSuggestions(searchTerm, currentLocation.coords.longitude, currentLocation.coords.latitude).then((results) => {
        var newResult = results?.map((value: any, index, array) => {
          array[index].coordinate = {
            "longitude": Number(value.longitude),
            "latitude": Number(value.latitude)
          }
          return value;
        });
        setLocationSuggestions(newResult);
        setKnownLocations(newResult);
        setIsLoading(false);
      })
    } catch (error) {
      console.error(error);
    }
  };

  function getMarkers(): React.ReactNode {
    return (locationSuggestions).map((location, index) => (
      <Marker key={index} coordinate={location.coordinate} title={location.name} description={location.address} />
    ))
  }

  if (isLoading) {
    return (
      <LoadingSpinnerComponent />
    );
  }
  else {
    if (errorMsg != null)
      return (
        <View style={[globalStyles.container]}>
          <Text style={{ color: 'black' }}>{errorMsg}</Text>
        </View>
      )
    else
      return (
        <>
          <View style={[globalStyles.container]}>
            <View style={[globalStyles.container, { maxHeight: '30%', alignItems: "center", justifyContent: "center" }]}>
              <Pressable style={[globalStyles.container, { paddingTop: '5%', alignItems: "center", justifyContent: "center" }]}>
                <Input style={[globalStyles.textInput, { maxWidth: '85%', alignItems: "center", justifyContent: "center" }]} placeholder="Search for location" maxLength={50} value={searchTerm} onChangeText={text => setSearchTerm(text)}
                  inputContainerStyle={globalStyles.textInputContainerStyle} placeholderTextColor={"black"} />
              </Pressable>
              <Pressable style={[globalStyles.searchBtn]} onPress={handleSearch}>
                <Text style={{ color: 'white' }}>Search</Text>
              </Pressable>
            </View>
            <View style={[globalStyles.container]}>
              <SearchResultsComponent listOfItems={locationSuggestions} onAdd={createNewLocation} />
            </View>
          </View>
        </>
      );
  }
}

