import axios from "axios";
import { LifeOarGroup, LifeOarTask } from "../../types/oarTypes";

export const getLocationSuggestions = (searchTerm: string, longitude: number, latitude: number) => {
	return axios
		.get(`locations/`, {
			params: {
				'searchTerm': searchTerm,
				'latitude': latitude,
				'longitude': longitude

			}
		})
		.then(function (response) {
			return (response.data);
		})
		.catch(function (error) {
			console.log(error.message);
		})
		.finally(function () {
			console.log('Successfully loaded group detail data.');
		});
}

export const getGroupLocations = (groupId: string) => {
	return axios
		.get(`groups/${groupId}/locations`)
		.then(function (response) {
			return (response.data);
		})
		.catch(function (error) {
			console.log(error.message);
		})
		.finally(function () {
			console.log('Successfully loaded group locations data.');
		});
};

export const deleteGroupLocation = (locationId: string, groupId: string) => {
	return axios
		.delete(`groups/${groupId}/locations/${locationId}`)
		.then(function (response) {
			return (response.data);
		})
		.catch(function (error) {
			console.log(error.message);
		})
		.finally(function () {
			console.log('Successfully deleted group location data.');
		});
}
