import { FontAwesome5 } from "@expo/vector-icons";
import React from "react";
import { Pressable, View } from "react-native";
import { globalStyles } from "../../constants/GlobalStyles";

export function ScheduleTaskbar({ navigation, route, scheduleType }) {
  let group = route.params && route.params.groupId ? route.params.groupId.toString() : null
  return (
      <View style={globalStyles.commandBarContainer}>
        <View style={globalStyles.headerButtonsContainer}>
          <Pressable onPress={() => navigation.navigate('AddEvent', { 'groupId': group })}>
            <FontAwesome5 name="plus" size={25} color={"black"} style={{ marginLeft: 15 }} />
          </Pressable>
        </View>
      </View>
  )
}