import { FontAwesome5 } from "@expo/vector-icons";
import React from "react";
import { Pressable, View } from "react-native";
import { globalStyles } from "../../constants/GlobalStyles";

export function LocationsTaskbar({ navigation, onReloadLocationData, route }) {
  let group = route.params && route.params.groupId ? route.params.groupId.toString() : null
  return (
      <View style={globalStyles.commandBarContainer}>
        <View style={globalStyles.headerButtonsContainer}>
          <Pressable onPress={() => navigation.navigate('AddLocation', {'groupId': group})}>
            <FontAwesome5 name="plus" size={25} color={"black"} style={{ marginLeft: 15 }} />
          </Pressable>
          <Pressable onPress={() => onReloadLocationData()}>
            <FontAwesome5 name="redo-alt" size={22} color={"black"} style={{ marginLeft: 15, marginTop: 2 }} />
          </Pressable>
        </View>
      </View>
  )
}