import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { globalStyles } from '../constants/GlobalStyles';
import { View, Text, ScrollView, Pressable } from 'react-native';
import JournalTaskbar from '../components/journal/JournalTaskbar';
import { JournalEntryTypesDict } from '../types/oarTypes';
import { useIsFocused } from "@react-navigation/native";
import DayOfWeekSelector from '../components/calendar/DayOfWeekSelector';
import { Calendar } from 'react-native-calendars';
import WeeklyCalendar from '../components/journal/Journal';
import moment from 'moment';
import DayOfWeekJournalSelector from '../components/journal/DayOfWeekJournalSelector';
import { ScheduleTaskbar } from '../components/schedules/ScheduleTaskbar';
import Schedule from '../components/schedules/Schedule';
import Journal from '../components/journal/Journal';

export default function JournalScreen({ navigation, route }) {
  const todaysLocalDate = moment.utc().local();
  const isFocused = useIsFocused();
  
  return (
    <View style={globalStyles.container}>
      <JournalTaskbar navigation={navigation} route={route} ></JournalTaskbar>
      <Journal navigation={navigation} route={route} ></Journal>
    </View>
  );
}