import axios from "axios";

import { BlockUserRequest, LifeOarLogEvent, LifeOarLogEventTypes, LifeOarUser } from "../../types/oarTypes";

export const getAllLifeOarUsers = () => {
	return axios
		.get(`users`)
		.then(function (response) {
			return (response.data);
		})
		.catch(function (error) {
			console.log(error.message);
		})
		.finally(function () {
			console.log('Successfully loaded All Life Oar Users data.');
		});
};

export const updateUserLastLogin = (user: LifeOarUser) => {
	axios
		.post('users', user)
		.then(function (response: any) {
			return (response.data);
		})
		.catch(function (error: any) {
			console.log(error.message);
		})
		.finally(function () {
			//setIsLoading(false);
		});
};

export const deleteUser = (objectId: string) => {
	axios
		.delete(`users/${objectId}`)
		.then(function (response: any) {
			return (response.data);
		})
		.catch(function (error: any) {
			alert(error.message);
		})
		.finally(function () {
			//setIsLoading(false);
		});
};

export const logUserLogin = (user: LifeOarUser) => {
	var payLoad: LifeOarLogEvent =
	{
		"eventTypeId": LifeOarLogEventTypes.Login,
		"data": JSON.stringify(user)
	}
	return axios({
		method: 'post',
		url: '/logevents',
		data: payLoad,
		headers: { 'Content-Type': 'application/json; charset=utf-8' }
	});
};

// export const logUserLoginAndLastLoginTime = (userValue: LifeOarUser) => {
//   axios.all([updateUserLastLogin,logUserLogin]).then(axios.spread((data1, data2) => {
// 		// output of req.
// 		console.log('data1', data1, 'data2', data2)
// 	}));
// }


//LEAVING THIS STUBBED IN FOR FUTURE USE BUT NO-OP RN
export const saveUserSettings = (groupId: string, messages: any[]) => {
	return axios
		.post(`groups/${groupId}/chat`, messages)
		.then(function (response) {
			return (response.data);
		})
		.catch(function (error) {
			alert(error.message);
		})
		.finally(function () {
			console.log('Successfully saved IMessages data.');
		});
}

export function blockUser(blockUserRequest: BlockUserRequest, userId: string) {
  return axios
		.post(`users/block`, blockUserRequest)
		.then(function (response) {
			return (response.data);
		})
		.catch(function (error) {
			alert(error.message);
		})
		.finally(function () {
			console.log('Successfully created block user request.');
		});
}


