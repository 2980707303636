import { useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TextInput,
  Button,
  TouchableOpacity,
} from "react-native"
import { AddEventComponent } from "../../components/events/AddEventComponent";
import { globalStyles } from "../../constants/GlobalStyles";

export default function AddEventScreen({ navigation, route }) {
  const [isLoading, setIsLoading] = useState(true);
  
  return (
    <View style={globalStyles.container}>
      <AddEventComponent navigation={navigation} route={route}></AddEventComponent>
    </View>
  );
}
