import AsyncStorage from "@react-native-async-storage/async-storage";
import moment from "moment";
import * as NavigationService from "../services/Navigation/navigationService"
//goToLogin Function on different js :
export function goToLogin(){
    NavigationService.navigate("Login", {});
}

export function goToLogout(){
  AsyncStorage.getItem('life-oar-user_id').then(function (value) {
    if (value) {
      var userInfo = JSON.parse(value);
      var expiry = moment.unix(userInfo.authTokenInfo.issuedAt).add(userInfo.authTokenInfo.expiresIn, "seconds")
      if (expiry < moment()) {
        AsyncStorage.removeItem('life-oar-user_id')
      }
    }
  })
  alert("Your authorization token has expired, you'll be taken to the login screen.")
  NavigationService.navigate("Auth", {});
}