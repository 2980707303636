import * as React from 'react';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import moment from 'moment';

import { goToLogin, goToLogout } from "./../navigation/navigationHelper"

export function configureAxios(bearerTokenValue: string) {
  axios.defaults.headers.common['Authorization'] = "Bearer " + bearerTokenValue;

  axios.interceptors.response.use(
    (response) => {
      return response
    },
    (err) => {
      if (err.response.status === 401) {
        goToLogout()
      }
    }
  )
}

export function updateAxiosBearerToken(bearerTokenValue: string) {
  axios.defaults.headers.common['Authorization'] = "Bearer " + bearerTokenValue;
}