// This file is used mainly to load resources you need to render the base of the app.
// This shows the splash screen, loads resources, then unlocks the screen.
import { FontAwesome } from '@expo/vector-icons';
import * as Font from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import * as React from 'react';

//import ConfigureAzureAd from './configureAzureAd';

export default function useCachedResources() {
  const [isLoading, setIsLoading] = React.useState(true);

  // Load any resources or data that we need prior to rendering the app
  React.useEffect(() => {
     function loadResourcesAndDataAsync() {
      try {
        SplashScreen.preventAutoHideAsync();
        // Load fonts. We want this to be first so we can load our spinner.
        Font.loadAsync({

         
          ...FontAwesome.font,
      
          
        });
      } catch (e) {
        // We might want to provide this error information to an error reporting service.
        console.warn(e);
      } finally {
        setIsLoading(false);
        
        SplashScreen.hideAsync();
      }
    }
    loadResourcesAndDataAsync();
  }, []);

  return isLoading;
}
