import React, { useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TextInput,
  Button,
  TouchableOpacity,
  Pressable,
} from "react-native";
import AxiosContext from '../../components/AxiosContext';
import axios from 'axios';
import { GroupSettings, LifeOarLogEventTypes, LifeOarGroup, LifeOarTask, LifeOarTaskTypes } from "../../types/oarTypes";
import { FontAwesome5 } from '@expo/vector-icons';
import { NewGroupComponent } from '../../components/groups/NewGroupComponent';
import { globalStyles } from "../../constants/GlobalStyles";
import { EditTaskComponent } from "../../components/tasks/EditTaskComponent";

export default function EditTaskScreen({ navigation, route, taskType }: any) {
  const [isLoading, setIsLoading] = useState(true);
  const [updatedTaskData, setUpdatedTaskData] = useState<LifeOarTask>();

  const updateTask = (updatedTaskData: LifeOarTask) => {
    axios
      .post('tasks', updatedTaskData)
      .then(function (response : any) {
        setUpdatedTaskData(response.data);
      })
      .catch(function (error : any) {
        console.log(error.message);
      })
      .finally(function () {
        setIsLoading(false);
      });
  };

  return (
    <View style={globalStyles.container}>
      <EditTaskComponent route={route} navigation={navigation}></EditTaskComponent>
    </View>
  );
}
