import { Badge, Avatar } from "@rneui/themed";
import React, { useState } from "react";
import { Pressable, View } from "react-native";
import { seaBlueColor } from "../../constants/Colors";

export default function DayOfWeekSelector({ }) {
  const [isSundaySelected, setIsSundaySelected] = useState(false);
  const [isMondaySelected, setIsMondaySelected] = useState(false);
  const [isTuesdaySelected, setIsTuesdaySelected] = useState(false);
  const [isWednesdaySelected, setIsWednesdaySelected] = useState(false);
  const [isThursdaySelected, setIsThursdaySelected] = useState(false);
  const [isFridaySelected, setIsFridaySelected] = useState(false);
  const [isSaturdaySelected, setIsSaturdaySelected] = useState(false);

  const onDayPressed = (nameOfDaySelected: string) => {
    switch (nameOfDaySelected) {
      case "Sunday": {
        setIsSundaySelected(!isSundaySelected);
        break;
      }
      case "Monday": {
        setIsMondaySelected(!isMondaySelected);
        break;
      }
      case "Tuesday": {
        setIsTuesdaySelected(!isTuesdaySelected);
        break;
      }
      case "Wednesday": {
        setIsWednesdaySelected(!isWednesdaySelected);
        break;
      }
      case "Thursday": {
        setIsThursdaySelected(!isThursdaySelected);
        break;
      }
      case "Friday": {
        setIsFridaySelected(!isFridaySelected);
        break;
      }
      case "Saturday": {
        setIsSaturdaySelected(!isSaturdaySelected);
        break;
      }
    }
  }

  return (
    <>
      <View style={{ flexDirection: "row" }}>
        <Pressable onPress={() => onDayPressed("Sunday")}>
          <Avatar rounded title="S" titleStyle={{ color: isSundaySelected == true ? "white" : "black" }} size={40} containerStyle={{ marginRight: 10, backgroundColor: isSundaySelected == true ? seaBlueColor : "lightgrey" }} />
        </Pressable>
        <Pressable onPress={() => onDayPressed("Monday")}>
          <Avatar rounded title="M" titleStyle={{ color: isMondaySelected == true ? "white" : "black" }} size={40} containerStyle={{ marginRight: 10, backgroundColor: isMondaySelected == true ? seaBlueColor : "lightgrey" }} />
        </Pressable>
        <Pressable onPress={() => onDayPressed("Tuesday")}>
          <Avatar rounded title="T" titleStyle={{ color: isTuesdaySelected == true ? "white" : "black" }} size={40} containerStyle={{ marginRight: 10, backgroundColor: isTuesdaySelected == true ? seaBlueColor : "lightgrey" }} />
        </Pressable>
        <Pressable onPress={() => onDayPressed("Wednesday")}>
          <Avatar rounded title="W" titleStyle={{ color: isWednesdaySelected == true ? "white" : "black" }} size={40} containerStyle={{ marginRight: 10, backgroundColor: isWednesdaySelected == true ? seaBlueColor : "lightgrey" }} />
        </Pressable>
        <Pressable onPress={() => onDayPressed("Thursday")}>
          <Avatar rounded title="T" titleStyle={{ color: isThursdaySelected == true ? "white" : "black" }} size={40} containerStyle={{ marginRight: 10, backgroundColor: isThursdaySelected == true ? seaBlueColor : "lightgrey" }} />
        </Pressable>
        <Pressable onPress={() => onDayPressed("Friday")}>
          <Avatar rounded title="F" titleStyle={{ color: isFridaySelected == true ? "white" : "black" }} size={40} containerStyle={{ marginRight: 10, backgroundColor: isFridaySelected == true ? seaBlueColor : "lightgrey" }} />
        </Pressable>
        <Pressable onPress={() => onDayPressed("Saturday")}>
          <Avatar rounded title="S" titleStyle={{ color: isSaturdaySelected == true ? "white" : "black" }} size={40} containerStyle={{ marginRight: 10, backgroundColor: isSaturdaySelected == true ? seaBlueColor : "lightgrey" }} />
        </Pressable>
      </View>
    </>
  )
}