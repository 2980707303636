import React, { useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TextInput,
  Button,
  TouchableOpacity,
  Pressable,
} from "react-native";
import AxiosContext from '../../components/AxiosContext';
import axios from 'axios';
import { GroupSettings, LifeOarLogEventTypes, LifeOarGroup, LifeOarTask, LifeOarTaskTypes } from "../../types/oarTypes";
import { FontAwesome5 } from '@expo/vector-icons';
import { globalStyles } from "../../constants/GlobalStyles";
import { AddLocationComponent } from "../../components/locations/AddLocationComponent";
import { LifeOarLocation } from "../../types/oarTypes";

export default function AddLocationScreen({ navigation, route }: any) {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <View style={globalStyles.container}>
      <AddLocationComponent route={route} navigation={navigation} ></AddLocationComponent>
    </View>
  );
}
