import { View, Text } from "react-native";

export default function JournalEntryScreen(date ) {

console.log("JournalEntryScreen item: ", date);


  return (
    <View>
      <Text>Journal Entry Screen</Text>
    </View>
  );





}