import * as WebBrowser from 'expo-web-browser';
import * as AuthSession from 'expo-auth-session';
import axios from 'axios';
import * as OarTypes from '../types/oarTypes';
import jwt_decode from "jwt-decode";
import * as ConfigureAxios from './configureAxios';
import AuthSessionPromptContext from '../components/AuthSessionPromptContext';
import React, { useState } from "react";
import { Platform } from 'react-native';

export const GetDiscoveryDocument = () => {
  var openIdProviderMetadata = {
    "issuer": "https://lifeoar.b2clogin.com/e19d329c-e3e9-4147-955d-2a45504a65c6/v2.0/",
    "authorization_endpoint": "https://lifeoar.b2clogin.com/lifeoar.io/b2c_1_sign_up_sign_in_01/oauth2/v2.0/authorize",
    "token_endpoint": "https://lifeoar.b2clogin.com/lifeoar.io/b2c_1_sign_up_sign_in_01/oauth2/v2.0/token",
    "end_session_endpoint": "https://lifeoar.b2clogin.com/lifeoar.io/b2c_1_sign_up_sign_in_01/oauth2/v2.0/logout",
    "jwks_uri": "https://lifeoar.b2clogin.com/lifeoar.io/b2c_1_sign_up_sign_in_01/discovery/v2.0/keys",
    "response_modes_supported": [
      "query",
      "fragment",
      "form_post"
    ],
    "response_types_supported": [
      "code",
      "code id_token",
      "code token",
      "code id_token token",
      "id_token",
      "id_token token",
      "token",
      "token id_token"
    ],
    "scopes_supported": [
      "openid"
    ],
    "subject_types_supported": [
      "pairwise"
    ],
    "id_token_signing_alg_values_supported": [
      "RS256"
    ],
    "token_endpoint_auth_methods_supported": [
      "client_secret_post",
      "client_secret_basic"
    ],
    "claims_supported": [
      "name",
      "emails",
      "given_name",
      "idp",
      "idp_access_token",
      "legalAgeGroupClassification",
      "postalCode",
      "family_name",
      "newUser",
      "oid",
      "sub",
      "tfp",
      "iss",
      "iat",
      "exp",
      "aud",
      "acr",
      "nonce",
      "auth_time"
    ]
  }

  var validDiscoveryDocument: AuthSession.DiscoveryDocument = {
    authorizationEndpoint: openIdProviderMetadata.authorization_endpoint,
    tokenEndpoint: openIdProviderMetadata.token_endpoint,
    endSessionEndpoint: openIdProviderMetadata.end_session_endpoint,
    discoveryDocument: openIdProviderMetadata
  };
  return validDiscoveryDocument;
}

export const GetRedirectUri = () => {
  return Platform.OS == 'ios' ? 'life-oar://Auth/login' : 'https://lifeoar.io/Auth/login'
  //return Platform.OS == 'ios' ? 'life-oar://Auth/login' : 'http://localhost:19006/Auth/login'
  //'exp://192.168.1.9:19000'  
  //'https://lifeoar.io/Auth/login'
  //'http://localhost:19006/Auth/login'
  //'life-oar://app.home'
}

export const GetAzureADB2CConfig = () => {
  var config =
  {
    clientId: "38a13109-a42f-4470-aac7-90d4ba4072cb",
    client_secret: "2Fr8Q~0iBtWkhH6Ag4-dNysEjQJ79y5BPamqjaoY",
    scopes: ['openid', 'profile', 'email', 'offline_access',
      'https://lifeoar.io/11c5a164-a562-4226-aef6-2891e0751ef8/LifeOar.Read',
      'https://lifeoar.io/11c5a164-a562-4226-aef6-2891e0751ef8/LifeOar.Admin'],
    redirectUri: GetRedirectUri(),
  };
  return config as AuthSession.AuthRequestConfig;
}

export default GetAzureADB2CConfig();