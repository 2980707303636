import { FontAwesome5 } from "@expo/vector-icons";
import { Switch } from "@rneui/themed";
import React, { useState } from "react";
import { Pressable, View, Text } from "react-native";
import { seaBlueColor } from "../../constants/Colors";
import { globalStyles } from "../../constants/GlobalStyles";

export function NewTaskTaskbar({ navigation, onReloadTaskData, route, taskType, onToggleIncludeGroupTasks }) {
  const [includeGroupTasks, setIncludeGroupTasks] = useState(false);
  let group = route.params && route.params.groupId ? route.params.groupId.toString() : null

  const toggleIncludeGroupTasksSwitch = (value) => {
    setIncludeGroupTasks(value)
    onToggleIncludeGroupTasks(value);
  };

  return (
    <View style={globalStyles.commandBarContainer}>
      <View style={globalStyles.headerButtonsContainer}>
        <Pressable onPress={() => navigation.navigate('AddTask', { 'groupId': group })}>
          <FontAwesome5 name="plus" size={25} color={"black"} style={{ marginLeft: 15 }} />
        </Pressable>
        <Pressable onPress={() => onReloadTaskData()}>
          <FontAwesome5 name="redo-alt" size={22} color={"black"} style={{ marginLeft: 15, marginTop: 2 }} />
        </Pressable>
        {taskType == "0" &&
          <View style={{ paddingLeft: 20, flexDirection: "row", alignSelf: "center", alignItems: "center" }}>
            <Text>Include group tasks:  </Text>
            <Text style={{ paddingRight: 15, fontWeight: 'bold' }}>{includeGroupTasks == true ? "Yes" : "No"}</Text>
            <Switch color={seaBlueColor} value={includeGroupTasks} style={{ transform: [{ scaleX: 1.0 }, { scaleY: 1.0 },] }} 
              onValueChange={(value) => toggleIncludeGroupTasksSwitch(value)} />
          </View>
        }
      </View>
    </View>
  )
}