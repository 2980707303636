import axios from "axios";
import { ImagePickerAsset } from "expo-image-picker";
import { IMessage } from "react-native-gifted-chat";

export const getGroupChatHistory = (groupId : string) => {
	return axios
		.get(`groups/${groupId}/chatHistory`)
		.then(function (response) {
			return (response.data);
		})
		.catch(function (error) {
			console.log(error.message);
		})
		.finally(function () {
			console.log('Successfully loaded group chat history data.');
		});
};

export const saveChatMessages = (groupId : string, messages: IMessage[]) => {
  return axios
		.post(`groups/${groupId}/chat`, messages)
		.then(function (response) {
			return (response.data);
		})
		.catch(function (error) {
			console.log(error.message);
		})
		.finally(function () {
			console.log('Successfully saved IMessages data.');
		});
}

export const saveChatImages = (groupId : string, images: ImagePickerAsset[]) => {
	
  return axios
		.post(`groups/${groupId}/uploadChatFile`, images)
		.then(function (response) {
			return (response.data);
		})
		.catch(function (error) {
			console.log(error.message);
		})
		.finally(function () {
			console.log('Successfully saved images data.');
		});
}
