import { FontAwesome5, FontAwesome6 } from "@expo/vector-icons";
import React from "react";
import { Pressable, View } from "react-native";
import { globalStyles } from "../../constants/GlobalStyles";

export default function JournalTaskbar({ navigation, route }) {
  return (
    <View style={globalStyles.commandBarContainer}>
      <View style={globalStyles.headerButtonsContainer}>
        <Pressable onPress={() => navigation.navigate('JournalSettings')}>
          <FontAwesome6 name="gear" size={25} color={"black"} style={{ marginLeft: 15 }} />
        </Pressable>
      </View>
    </View>
  )
}