// Example of Splash, Login and Sign Up in React Native
// https://aboutreact.com/react-native-login-and-signup/

// Import React and Component
import React, { useEffect, useState } from 'react';
import * as ConfigureAxios from '../hooks/configureAxios';

import { SpaceMono_400Regular, useFonts } from '@expo-google-fonts/space-mono';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { LoadingSpinnerComponent } from '../components/LoadingSpinner';
import UserInfoContext from '../components/UserInfoContext';

const SplashScreen = ({ navigation }: any) => {
  const [animating, setAnimating] = useState(true);
  const { setUserInfo } = React.useContext(UserInfoContext);

  let [fontsLoaded] = useFonts({
    SpaceMono_400Regular,
  });
  
  useEffect(() => {
    setTimeout(() => {
      AsyncStorage.getItem('life-oar-user_id').then(function (value) {
        if (value) {
          var userInfo = JSON.parse(value);
          ConfigureAxios.configureAxios(userInfo.authTokenInfo.accessToken);
          setUserInfo(userInfo);
        }
        navigation.replace(value === null ? 'Auth' : 'Root')
        setAnimating(false);
      },
      );
    }, 250);
  }, []);

  return (
    <LoadingSpinnerComponent />
  );
};

export default SplashScreen;
