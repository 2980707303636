import { FontAwesome5 } from '@expo/vector-icons';
import * as React from 'react';
import {
  StyleSheet,
  Text,
  View,
  Image,
  TextInput,
  Button,
  TouchableOpacity,
  Pressable,
} from "react-native";
import { LoadingSpinnerComponent } from '../../components/LoadingSpinner';
import { globalStyles } from '../../constants/GlobalStyles';
import { LifeOarTask } from '../../types/oarTypes';
import * as TaskService from '../../services/Tasks/tasksService'
import { TaskComponent } from '../../components/tasks/TaskListComponent';
import { NewTaskTaskbar } from '../../components/tasks/TaskTaskbar';
import { useState } from 'react';
import { useIsFocused } from "@react-navigation/native";

export default function TasksScreen({ navigation, route }: any) {
  const [isLoading, setIsLoading] = React.useState(true);
  const [includeGroupTasks, setIncludeGroupTasks] = useState(false)
  const [taskData, setTaskData] = React.useState<LifeOarTask[]>([]);

  const isFocused = useIsFocused();

  React.useEffect(() => {
    loadTaskData(includeGroupTasks);
  }, [navigation, route, isFocused]);

  const loadTaskData = (includeGroupTasks: boolean) => {
    TaskService.getTasks(includeGroupTasks).then((results) => {
      setTaskData(results);
    })
    setIsLoading(false);
  };

  const toggleIncludingGroupTasks = (value: boolean) => {
    setIncludeGroupTasks(value)
    loadTaskData(value)
  }

  const deleteTask = (taskId: string) => {
    setIsLoading(true)
    TaskService.deleteTask(taskId).then(() => {
      
    })

    loadTaskData(includeGroupTasks);
    setIsLoading(false);
  };

  const editTask = (taskId) => {
    setIsLoading(true)
    navigation.navigate('EditTask', { 'taskId': taskId })
    setIsLoading(false);
  };

  if (isLoading) {
    return (
      <LoadingSpinnerComponent />
    );
  } else {
    return (
      <>
        <NewTaskTaskbar onToggleIncludeGroupTasks={toggleIncludingGroupTasks} navigation={navigation} onReloadTaskData={loadTaskData} route={route} taskType='0'></NewTaskTaskbar>
        <View style={globalStyles.container}>
          <TaskComponent navigation={navigation} route={route} listOfItems={taskData} onDelete={deleteTask} onEdit={editTask} taskType='0'></TaskComponent>
        </View>
      </>
    );
  }
}