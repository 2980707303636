import * as React from 'react';
import {
  StyleSheet,
  Text,
  View,
  Image,
  TextInput,
  Button,
  TouchableOpacity,
  ScrollView,
  Platform,
} from "react-native";
import AxiosContext from '../components/AxiosContext';
import axios from 'axios';
import { globalStyles } from '../constants/GlobalStyles';
import { BarChart, LineChart, PieChart, PopulationPyramid } from "react-native-gifted-charts";
import { getJournalEntriesForMonth, getEnabledJournalTypes } from '../services/Journal/journalService';
import { useEffect, useState } from 'react';
import { JournalEntryTypesDict } from '../types/oarTypes';
import { LoadingSpinnerComponent } from '../components/LoadingSpinner';
import { seaBlueColor } from '../constants/Colors';
import moment from 'moment';
import { useIsFocused } from '@react-navigation/native';

export default function HomeScreen({ route, navigation }) {
  const data = [{ value: 50, label: "50%" }, { value: 80 }, { value: 90 }, { value: 70 }]
  const [isLoading, setIsLoading] = useState(true);
  const [journalData, setJournalData] = useState([]);
  const [enabledJournalTypes, setEnabledJournalTypes] = useState([]);
  const [endResult, setEndResult] = useState([]);
  const isFocused = useIsFocused();

  useEffect(() => {
    var result = [];
    var today = new Date();
    getEnabledJournalTypes().then((enabledTypes) => {
      setEnabledJournalTypes(enabledTypes);
      var types = enabledTypes.map((type) => { return type._id });
      getJournalEntriesForMonth(today.getFullYear(), today.getMonth() + 1).then((journalResponse) => {

        setJournalData(journalResponse);

        types.forEach((journalType) => {
          var dateData = [];
          journalResponse.forEach((entry) => {
            entry.journalEntries.forEach((dataItem) => {
              if (parseInt(dataItem.id) == journalType) {
                dateData.push({ label: moment.utc(entry.date).startOf("day").format("MM-DD"), value: parseInt(dataItem.value) });
              }
            });
          });
          result.push({ journalType: journalType, dateData: dateData });
        });
        setEndResult(result);
        setIsLoading(false);
      });
    });
  }, [route, navigation, isFocused]);

  if (isLoading) {
    return (
      <LoadingSpinnerComponent />
    );
  } else {
    if (enabledJournalTypes.length == 0)
      return (
        <View style={globalStyles.centeredContainer}>
          <Text style={globalStyles.agendaItemTitle}>You have no active Journal settings. Use the cog in the upper left of the Journal screen to get started.</Text>
        </View>
      );
    return (
      <ScrollView>
        <View style={globalStyles.centeredContainer}>
          {endResult.map((result) => {
            return (
              <View >
                <Text style={globalStyles.cardTitle}>{JournalEntryTypesDict.types[result.journalType].name}</Text>
                <LineChart data={result.dateData}
                  maxValue={10}
                  width={Platform.OS == "web" ? 1400 : 650}
                  color={seaBlueColor}
                  isAnimated={true}
                />
              </View>
            );
          })}
        </View>
      </ScrollView>
    )
  }
}
