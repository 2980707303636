
import { ActivityIndicator } from "react-native-paper";
import { globalStyles } from "../constants/GlobalStyles";
import { StyleSheet, Image, View } from "react-native";
import { seaBlueColor } from "../constants/Colors";
import { ThemedView } from "./ThemedView";

export function LoadingSpinnerComponent() {
	return (
		<ThemedView style={globalStyles.spinner}>
			<Image source={require('../assets/oar-icon-12.png')} style={{ width: 100, height: 100, tintColor:seaBlueColor}}/>
			<ActivityIndicator animating={true} color={seaBlueColor} size="large" style={globalStyles.activityIndicator} />
		</ThemedView>
	)
}