import axios from "axios";
import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View, SafeAreaView, SectionList, StatusBar, FlatList, VirtualizedList, Pressable, Platform, Linking, Image } from "react-native";
import { globalStyles } from "../../constants/GlobalStyles";
import { LifeOarGroup } from "../../types/oarTypes";
import { FontAwesome, FontAwesome5 } from '@expo/vector-icons';
import MapView, { Marker, Circle } from 'react-native-maps';
import * as Location from 'expo-location';
import * as Device from 'expo-device';
import { LoadingSpinnerComponent } from "../LoadingSpinner";
import { ThemedView } from "../ThemedView";

export function AlbumRecommendationListComponent({ listOfAlbums }: any) {

  const getAlbum = (data: any[], index: number) => (
    {
      id: `${data[index].id}`,
      name: `${data[index].name}`,
      type: `${data[index].album_type}`,
      releaseDate: `${data[index].release_date}`,
      artist: `${data[index].artists[0].name}`,
      coverImage: `${data[index].images[0].url}`,
      spotifyWebUrl: `${data[index].external_urls.spotify}`,
      spotifyAppUri: `${data[index].uri}`,
    }
  );

  const getAlbumCount = (data: any[]) => {
    if (typeof data == "undefined") {
      return 0
    }
    else return data.length
  }

  const launchSpotifyLink = (item: any[]) => {
    Linking.canOpenURL(item.spotifyAppUri).then((supported) => {
      if (Platform.OS == 'web' || !supported) {
        Linking.openURL(item.spotifyWebUrl)
      }
      else {
        Linking.openURL(item.spotifyAppUri)
      }
    })
  }

  const Item = ({ item }) => (
    <ThemedView style={[globalStyles.listItemContainer, {flexGrow:2}]}>
      <ThemedView style={{ flexDirection: "row", width: '100%' }}>
        <Pressable style={{ flexDirection: "row" }}
          onPress={() => launchSpotifyLink(item)}>
          <ThemedView style={[globalStyles.container, { flexDirection: 'row', flexWrap: 'wrap', paddingLeft: 5 }]}>
            <Image style={{width: 100, height: 100,}} source={{uri: item.coverImage}}></Image>
            <ThemedView style={{ flexDirection: "row", alignItems: "center" }}>
              <ThemedView style={[globalStyles.container, { alignItems: "flex-start", padding: 5 }]}>
                <Text style={globalStyles.listItemTitle}>{item.name}</Text>
                <Text style={globalStyles.listItemText}>Artist: {item.artist}</Text>
                <Text style={globalStyles.listItemText}>Release Date: {item.releaseDate}</Text>
              </ThemedView>
            </ThemedView>
          </ThemedView>
        </Pressable>
      </ThemedView>
    </ThemedView>
  );
  return (
    <SafeAreaView style={{ width: "95%", flex: 1 }}>
      <VirtualizedList style={globalStyles.listContainer}
        data={listOfAlbums}
        initialNumToRender={20}
        renderItem={({ item }) => <Item item={item} />}
        keyExtractor={item => item.id}
        getItemCount={getAlbumCount}
        getItem={getAlbum}
      />
    </SafeAreaView>
  );
}
