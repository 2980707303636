
export const seaBlueColor = "#006994";
export const defaultFont = 'space-mono'
const tintColorLight = '#0a7ea4';
const tintColorDark = '#fff';

export const ThemeColors ={
  light: {
    text: '#11181C',
    background: '#fff',
    tint: tintColorLight,
    icon: seaBlueColor,
    tabIconDefault: '#687076',
    tabIconSelected: seaBlueColor,
  },
  dark: {
    text: '#ECEDEE',
    background: '#151718',
    tint: tintColorDark,
    icon: '#9BA1A6',
    tabIconDefault: '#9BA1A6',
    tabIconSelected: tintColorDark,
  },
};


