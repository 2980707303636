import axios from "axios";
import { LifeOarTask } from "../../types/oarTypes";

export const getTasks = (includeGroupTasks: boolean) => {
	return axios
		.get('tasks', { params: {includeGroupTasks: includeGroupTasks}})
		.then(function (response) {
			return (response.data);
		})
		.catch(function (error) {
			console.log(error.message);
		});
};

export const getTaskDetail = (taskId: string) => {
	return axios
		.get(`tasks/${taskId}`)
		.then(function (response) {
			return (response.data);
		})
		.catch(function (error) {
			console.log(error.message);
		});
}

export const getGroupTaskDetail = (groupId: string, taskId: string) => {
	return axios
		.get(`groups/${groupId}/tasks/${taskId}`)
		.then(function (response) {
			return (response.data);
		})
		.catch(function (error) {
			console.log(error.message);
		});
}

export const saveNewTask = (task: LifeOarTask) => {
	return axios
		.post('tasks', task)
		.then(function (response) {
			return (response.data);
		})
		.catch(function (error) {
			console.log(error.message);
		});
};

export const deleteTask = (taskId: string) => {
	return axios
		.delete(`tasks/${taskId}`)
		.then(function (response) {
			return (response.data);
		})
		.catch(function (error) {
			console.log(error.message);
		});
};

export const updateTask = (taskId: string, task: LifeOarTask, originalAssignedToId: string) => {
	return axios
		.put(`tasks/${taskId}`,{"originalAssignedToId" : originalAssignedToId, "task":task} )
		.then(function (response) {
			return (response.data);
		})
		.catch(function (error) {
			console.log(error.message);
		});
};



