import { FontAwesome5 } from '@expo/vector-icons';
import React, { useState } from "react";
import { Linking, Platform, Pressable, SafeAreaView, Text, View, VirtualizedList } from "react-native";
import { globalStyles } from "../../constants/GlobalStyles";

export function LocationListComponent({ navigation, route, listOfItems, onDelete }: any) {
  const [errorMsg, setErrorMsg] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [region, setRegion] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const groupId = route.params.groupId;

  const getItem = (data: any[], index: number) => (
    {
      id: `${data[index]._id}`,
      title: `${data[index].title}`,
      address: `${data[index].address}`,
      latitude: `${data[index].latitude}`,
      longitude: `${data[index].longitude}`,
    }
  );

  const getListItemCount = (data: any[]) => {
    if (typeof data == "undefined") {
      return 0
    }
    else return data.length
  }

  const launchMapLink = (item: any[]) => {
    Linking.canOpenURL(`geo:${item.latitude},${item.longitude}`).then((supported) => {
      if (Platform.OS == 'web' || !supported) {
        Linking.openURL(`https://www.google.com/maps/search/?api=1&query=${item.latitude}%2C${item.longitude}`)
      }
      else {
        Linking.openURL(`geo:${item.latitude},${item.longitude}`)
      }
    })
  }

  function ListIcon(props: {
    name: React.ComponentProps<typeof FontAwesome5>['name'];
    color: string;
  }) {
    return <FontAwesome5 size={25} style={{}} {...props} />;
  }

  const Item = ({ item }) => (
    <View style={globalStyles.listItemContainer}>
      <View style={{ flexDirection: "row", width: '95%', flexWrap: "nowrap", paddingLeft: 15, flex: 3 }}>
        <Pressable style={{ flexDirection: "row", flexWrap: "nowrap" }}
          onPress={() => launchMapLink(item)}>
          <View style={[globalStyles.centeredContainer, { flexDirection: 'row', flexWrap: 'nowrap', paddingLeft: 5 }]}>
            <ListIcon name="map-marker-alt" color="black"></ListIcon>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <View style={[globalStyles.centeredContainer, { alignItems: "flex-start", padding: 5 }]}>
                <Text style={globalStyles.listItemTitle}>{item.title}</Text>
                <Text style={globalStyles.listItemText}>{item.address.toString().split(',')[0].toString().trim()}</Text>
                <Text style={globalStyles.listItemText}>{item.address.toString().split(',')[1].toString().trim()}{', '}{item.address.toString().split(',')[2].toString().trim()}</Text>
              </View>
            </View>
          </View>
        </Pressable>
      </View>
      <View style={{ flexDirection: "row-reverse", alignItems: "center", paddingRight: 10 }}>
        <Pressable onPress={() => onDelete(item.id, groupId)} >
          <ListIcon name="times-circle" color="black"></ListIcon>
        </Pressable>

      </View>
    </View>
  );
  return (
    <SafeAreaView style={{ width: "95%", flex: 1 }}>
      <VirtualizedList style={globalStyles.listContainer}
        data={listOfItems}
        initialNumToRender={20}
        renderItem={({ item }) => <Item item={item} />}
        keyExtractor={item => item.id}
        getItemCount={getListItemCount}
        getItem={getItem}
      />
    </SafeAreaView>
  );
}
