import * as React from "react"
import { FontAwesome, FontAwesome5 } from '@expo/vector-icons'
import Colors, { seaBlueColor } from '../../constants/Colors';
import { Modal, Pressable, View, Text, Alert, Image } from 'react-native';
import { globalStyles } from "../../constants/GlobalStyles";
import AsyncStorage from '@react-native-async-storage/async-storage';
import navigation from "../../navigation";
import { useNavigation, NavigationContainer } from '@react-navigation/native';
import { Avatar, Divider } from "@rneui/base";
import UserInfoContext from '../../components/UserInfoContext';
import { UserInfo } from '../../types/oarTypes'
import { getInitialsFromName } from "../../helpers/stringHelpers";
import { useState } from "react";
import * as UserService from "../../services/Users/userService";

export function GlobalHeaderLeft({ userInfo, navigation, props }): React.ReactNode {
	return (
		<>
			<Pressable onPress={(pressed) => navigation.navigate("Splash")}
				style={({ pressed }) => ({opacity: pressed ? 0.5 : 1,})}>
				<Image source={require("../../assets/oar-icon-12.png")} style={{ width: 40, height: 40, marginLeft: 10 }}></Image>
			</Pressable>
		</>
	)
}

export function GroupsHeaderLeft({ props }): React.ReactNode {
  return (
	<>
	  
	</>
  )
}

export function GlobalHeaderRight({ userInfo, navigation }): React.ReactNode {
	const initials = getInitialsFromName(userInfo.name)
	const [isUserSettingsModalVisible, setIsUserSettingsModalVisible] = useState(false);
	const [isConfirmDeletionModalVisible, setIsConfirmDeletionModalVisible] = useState(false);

	const openUserSettingsModal = (navigation) => {
		setIsUserSettingsModalVisible(true);
	}

	const logout = () => {
		AsyncStorage.removeItem('life-oar-user_id')
		navigation.navigate("Splash");
	}

	const onRequestAccountDeletion = (navigation) => {
		setIsUserSettingsModalVisible(false);
		setIsConfirmDeletionModalVisible(true);
	}

	const onConfirmedAccountCancellation = (navigation) => {
		UserService.deleteUser(userInfo.objectId);
		setIsConfirmDeletionModalVisible(false);
		logout();
	}

	return (
		<View style={globalStyles.headerRightContainer}>
			<Pressable onPress={(pressed) => logout()} style={({ pressed }) => ({ opacity: pressed ? 0.5 : 1, })}>
				<FontAwesome5 name="ban" size={30} color={"black"} style={{ marginRight: 15 }} />
			</Pressable>
			<Pressable onPress={(pressed) => openUserSettingsModal(navigation)}
				style={({ pressed }) => ({ opacity: pressed ? 0.5 : 1, })}>
				<Avatar rounded title={initials} size={30} containerStyle={{ marginRight: 15, backgroundColor: seaBlueColor }} />
			</Pressable>
			<Modal animationType="slide" visible={isUserSettingsModalVisible} transparent={true}
				onRequestClose={() => setIsUserSettingsModalVisible(false)}
				style={globalStyles.modalContainer}>
				<View style={globalStyles.centeredView}>
					<View style={globalStyles.titleContainer}>
						<Text style={globalStyles.modalTitle}>User Settings and Actions</Text>
						<Pressable onPress={() => setIsUserSettingsModalVisible(false)}>
							<FontAwesome name="close" color="black" size={22} />
						</Pressable>
					</View>
					<View style={globalStyles.modalView}>
						<Pressable onPress={onRequestAccountDeletion}>
							<View style={{ flexDirection: "row", flexWrap: "wrap" }}>
								<FontAwesome name="close" color="black" size={22} style={{ paddingRight: 10 }} />
								<Text>Request Account Deletion</Text>
							</View>
							<Divider width={3} color={seaBlueColor} />
						</Pressable>
					</View>
				</View>
			</Modal>
			<Modal animationType="slide" visible={isConfirmDeletionModalVisible} transparent={true}
				onRequestClose={() => setIsConfirmDeletionModalVisible(false)}
				style={globalStyles.modalContainer}>
				<View style={globalStyles.centeredView}>
					<View style={globalStyles.titleContainer}>
						<Text style={globalStyles.modalTitle}>Verify Account Deletion</Text>
						<Pressable onPress={() => setIsConfirmDeletionModalVisible(false)}>
							<FontAwesome name="close" color="black" size={22} />
						</Pressable>
					</View>
					<View style={globalStyles.modalView}>
						<Pressable onPress={onConfirmedAccountCancellation}>
							<View style={{ flexDirection: "row", flexWrap: "wrap" }}>
								<FontAwesome name="check" color="black" size={22} style={{ paddingRight: 10 }} />
								<Text>Confirm Account Deletion</Text>
							</View>
						</Pressable>
						<Pressable onPress={() => setIsConfirmDeletionModalVisible(false)}>
							<Divider width={3} color={seaBlueColor} />
							<View style={{ flexDirection: "row", flexWrap: "wrap" }}>
								<FontAwesome name="arrow-left" color="black" size={22} style={{ paddingRight: 10 }} />
								<Text>Don't Delete Account</Text>
							</View>
							<Divider width={3} color={seaBlueColor} />
						</Pressable>
					</View>
				</View>
			</Modal>
		</View>
	)
}