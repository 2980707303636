import { useCallback, useEffect, useState } from "react";
import * as React from "react";
import { LifeOarGroupTypes, LifeOarGroup, LifeOarUser, LifeOarTaskTypes, TaskPriority, TaskState, LifeOarTask, EventReminderTimeOptions, TaskReminderTimeOptions } from "../../types/oarTypes";
import { Keyboard, SafeAreaView, ScrollView, StatusBar, StyleSheet, TextInput, TouchableOpacity, View, Text, Modal, Pressable } from "react-native";
import DropDownPicker from 'react-native-dropdown-picker';
import { globalStyles } from "../../constants/GlobalStyles";
import { saveNewGroup } from "../../services/Groups/groupsService";
import { CheckBox, Divider, Icon, Input, ListItem, Switch } from "@rneui/themed";
import { FontAwesome5 } from '@expo/vector-icons';
import { LoadingSpinnerComponent } from "../LoadingSpinner";
import * as UserService from "../../services/Users/userService";
import * as GroupService from '../../services/Groups/groupsService'
import UserInfoContext from "../UserInfoContext";
import { saveNewTask } from "../../services/Tasks/tasksService";
import { seaBlueColor } from "../../constants/Colors";
import DatePicker from "../calendar/DatePicker";

export function AddTaskComponent({ navigation, route }) {
  let taskType = route.params.groupId == null ? "Personal" : "Group"
  let groupId = route.params.groupId == null ? null : route.params.groupId
  const { userInfo } = React.useContext(UserInfoContext);

  const taskTypeObj = LifeOarTaskTypes.find(task => task.label === taskType)

  const [isLoading, setIsLoading] = useState(true);
  const [newTaskTitle, setNewTaskTitle] = useState<string>('');

  const [taskTypeOpen, setTaskTypeOpen] = useState(false);
  const [taskTypeDropdownValue, setTaskTypeDropdownValue] = useState<number>(taskTypeObj.value);
  const [taskTypeItems, setTaskTypeItems] = useState<any[]>(LifeOarTaskTypes);

  const [taskPriorityOpen, setTaskPriorityOpen] = useState(false);
  const [taskPriorityDropdownValue, setTaskPriorityDropdownValue] = useState<number>(0);
  const [taskPriorityItems, setTaskPriorityItems] = useState<any>(TaskPriority);

  const [taskStateOpen, setTaskStateOpen] = useState(false);
  const [taskStateDropdownValue, setTaskStateDropdownValue] = useState<number>(0);
  const [taskStateItems, setTaskStateItems] = useState<any>(TaskState);

  const [dueDateOpen, setDueDateOpen] = useState(false);
  const [dueDateValue, setDueDateValue] = useState(null);

  const [assigned, setAssigned] = useState<any[]>([]);
  const [assignedOpen, setAssignedOpen] = useState(false);
  const [assignedDropdownValue, setAssignedDropdownValue] = useState(taskTypeObj.label === "Group" ? null : userInfo.objectId);

  const [interestedParties, setInterestedParties] = useState<any[]>([]);
  const [interestedPartiesOpen, setInterestedPartiesOpen] = useState(false);
  const [interestedPartiesDropdownValue, setInterestedPartiesDropdownValue] = useState<any[]>([]);

  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [formattedDate, setFormattedDate] = useState('')
  const [selectedDate, setSelectedDate] = useState(null);
  const dateInput = React.useRef(null);
  const [dateSelected, setDateSelected] = useState(false);

  const [onReminderTimePickerOpen, setOnReminderTimePickerOpen] = useState(false);
  const [reminderTimePickerDropdownValue, setReminderTimePickerDropdownValue] = useState<number>(1);
  const [reminderTimePickerItems, setReminderTimePickerItems] = useState<typeof TaskReminderTimeOptions>(TaskReminderTimeOptions);

  const [isReminderSet, setIsReminderSet] = useState(true);

  const minDate = new Date(); // Today
  const maxDate = new Date(minDate.getFullYear() + 5, minDate.getMonth() + 1, minDate.getDay())

  useEffect(() => {
    if (taskType === "Group") {
      loadGroupUsers(route.params.groupId).then((results: any[]) => {
        var mappedResults = results.map(x => ({
          value: x.objectId,
          label: x.name
        }))
        setAssigned(mappedResults);
        setInterestedParties(mappedResults);
      })
    }
    setIsLoading(false);
  }, [navigation, route])

  const loadGroupUsers = async (groupId: string) => {
    return GroupService.getGroupUsers(groupId)
  }

  const handleSubmit = (event: any) => {
    if (newTaskTitle && taskTypeObj !== null) {
      var assigned = taskType === "Personal" ? userInfo.objectId : assignedDropdownValue
      var newTask: LifeOarTask = {
        title: `${newTaskTitle}`,
        priority: taskPriorityDropdownValue,
        state: taskStateDropdownValue,
        type: taskTypeObj.value,
        assigned: assigned,
        interestedParties: taskTypeObj.label === "Personal" ? null : interestedPartiesDropdownValue,
        dueDate: dueDateValue,
        dateCompleted: taskStateDropdownValue == 3 ? new Date().toISOString() : "", 
        groupId: groupId,
        reminderMinsPrior: isReminderSet == true ? reminderTimePickerDropdownValue : null
      }
      saveNewTask(newTask).then((results) => {
        if (taskType === "Personal") {
          navigation.navigate('Tasks')
        }
        else
          navigation.navigate('GroupDetail', { 'groupId': groupId })
      });
    }
    else alert("You clicked submit")
  }

  const handleTitleChange = (newTitle: string) => {
    setNewTaskTitle(newTitle);
    setInterestedPartiesOpen(false);
    setAssignedOpen(false);
    setTaskPriorityOpen(false);
    setTaskStateOpen(false);
  }

  const onTaskTypeOpen = useCallback(() => {
    setInterestedPartiesOpen(false);
    setAssignedOpen(false);
    setTaskPriorityOpen(false);
    setTaskStateOpen(false);
  }, []);

  const onAssignedOpen = useCallback(() => {
    setInterestedPartiesOpen(false);
    setTaskTypeOpen(false);
    setTaskPriorityOpen(false);
    setTaskStateOpen(false);
  }, []);

  const onTaskPriorityOpen = useCallback(() => {
    setAssignedOpen(false);
    setTaskTypeOpen(false);
    setTaskStateOpen(false);
    setInterestedPartiesOpen(false);
  }, []);

  const onTaskStateOpen = useCallback(() => {
    setAssignedOpen(false);
    setTaskTypeOpen(false);
    setTaskPriorityOpen(false);
    setInterestedPartiesOpen(false);
  }, []);

  const onInterestedPartiesOpen = useCallback(() => {
    setAssignedOpen(false);
    setTaskTypeOpen(false);
    setTaskPriorityOpen(false);
    setTaskStateOpen(false);
  }, []);

  const onDatePickerFocus = () => {
    if (!dateSelected) { // only open the modal if date is not selected
      setDatePickerOpen(true);
    }
  }

  const onDateChange = (selectedDate: moment.Moment) => {
    setSelectedDate(selectedDate.utc().format())
    setDueDateValue(selectedDate.utc().format())
    var duration = selectedDate.valueOf() - minDate.valueOf()
    var days = selectedDate.diff(minDate, "days") + 1
    setFormattedDate(`${selectedDate.format("MM/DD/YYYY")} (${days} days from now)`)
    setDateSelected(true);
    setDatePickerOpen(false)
  }

  if (isLoading) {
    return (
      <LoadingSpinnerComponent />
    );
  } else {
    return (
   
      <View style={[globalStyles.container]}>
        <View style={[globalStyles.formContainer]}>
        <View style={[globalStyles.container]}>
          <Pressable style={globalStyles.centeredContainer}>
          
            <Input style={globalStyles.textInput} placeholder="Task title" maxLength={55} value={newTaskTitle} onChangeText={handleTitleChange}
              inputContainerStyle={globalStyles.textInputContainerStyle} placeholderTextColor={"black"} />
              
          </Pressable>
          </View>
          <View style={[globalStyles.dropDownContainer, { zIndex: taskPriorityOpen ? 1 : 0 }]}>
            <Pressable style={globalStyles.container}>
              <DropDownPicker open={taskPriorityOpen}
                value={taskPriorityDropdownValue}
                items={taskPriorityItems}
                setOpen={setTaskPriorityOpen}
                onOpen={onTaskPriorityOpen}
                setValue={setTaskPriorityDropdownValue}
                setItems={setTaskPriorityItems}
                placeholder="Task priority"
                style={{ borderColor: 'lightgrey' }}
                dropDownContainerStyle={{ borderColor: 'lightgrey' }} />
            </Pressable>
          </View>
          <View style={[globalStyles.dropDownContainer, { zIndex: taskStateOpen ? 1 : 0 }]}>
            <Pressable style={globalStyles.container}>
              <DropDownPicker open={taskStateOpen}
                value={taskStateDropdownValue}
                items={taskStateItems}
                setOpen={setTaskStateOpen}
                onOpen={onTaskStateOpen}
                setValue={setTaskStateDropdownValue}
                setItems={setTaskStateItems}
                placeholder="Task state"
                style={{ borderColor: 'lightgrey' }}
                dropDownContainerStyle={{ borderColor: 'lightgrey' }} />
            </Pressable>
          </View>
          <View style={[globalStyles.container, { flexDirection: "row-reverse", alignItems: "center", paddingLeft: 35, paddingRight: 10 }]}>
            <Pressable style={{ paddingBottom: 30 }} onPress={() => setDatePickerOpen(true)}>
              <FontAwesome5 name="edit" size={30} color={"black"} />
            </Pressable>
            <Input ref={dateInput} style={[globalStyles.textInput, { display: "flex", zIndex: datePickerOpen ? 0 : 1 }]}
              placeholder='Due date' placeholderTextColor={"black"} inputContainerStyle={globalStyles.textInputContainerStyle}
              value={formattedDate} onFocus={onDatePickerFocus} />
          </View>
          <View style={[globalStyles.container, { display: datePickerOpen ? "flex" : "none", flexShrink: 1, zIndex: datePickerOpen ? 0 : 1 }]}>
            <TouchableOpacity style={[globalStyles.container]}>
              <DatePicker route={route} navigation={navigation} isOpen={datePickerOpen} minDate={minDate} maxDate={maxDate} onDateChange={onDateChange} ></DatePicker>
            </TouchableOpacity>
          </View>
          <View style={[globalStyles.container, {
            flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'flex-start'
            , paddingLeft: 20, zIndex: onReminderTimePickerOpen ? 10 : 0
          }]}>
            
            <Text>Reminder:  </Text>
            <Text style={{ paddingRight: 15, fontWeight: 'bold' }}>{isReminderSet == true ? "Yes" : "No"}</Text>
            <View style={[globalStyles.container, { flexDirection: 'row', justifyContent: 'flex-start' }]}>
              <Switch disabled={false} color={seaBlueColor} value={isReminderSet} style={{ transform: [{ scaleX: 1.3 }, { scaleY: 1.3 },] }}
                onValueChange={(value) => setIsReminderSet(value)} />

              {/* {false == true &&
                <View style={{ maxWidth: '75%', paddingLeft: 25, zIndex: 100 }}>
                  <DropDownPicker open={onReminderTimePickerOpen}
                    value={reminderTimePickerDropdownValue}
                    items={reminderTimePickerItems}
                    setOpen={setOnReminderTimePickerOpen}
                    setValue={setReminderTimePickerDropdownValue}
                    setItems={setReminderTimePickerItems}
                    placeholder="Length"
                    style={{ borderColor: 'lightgrey' }}
                    dropDownContainerStyle={{ borderColor: 'lightgrey' }}
                  />
                </View>
              } */}
            </View>
          </View>
          {taskTypeObj.label == "Group" &&
            <>
              <View style={[globalStyles.dropDownContainer, { zIndex: assignedOpen ? 1 : 0 }]}>
                <TouchableOpacity style={globalStyles.container}>
                  <DropDownPicker open={assignedOpen}
                    value={assignedDropdownValue}
                    items={assigned}
                    setOpen={setAssignedOpen}
                    setValue={setAssignedDropdownValue}
                    setItems={setAssigned}
                    onOpen={onAssignedOpen}
                    placeholder="Assigned to"
                    style={{ borderColor: 'lightgrey' }}
                    dropDownContainerStyle={{ borderColor: 'lightgrey' }}
                    multiple={false}
                  />
                </TouchableOpacity>
              </View>
              <View style={[globalStyles.dropDownContainer, { zIndex: interestedPartiesOpen ? 1 : 0 }]}>
                <TouchableOpacity style={globalStyles.container}>
                  <DropDownPicker open={interestedPartiesOpen}
                    value={interestedPartiesDropdownValue}
                    items={interestedParties}
                    setOpen={setInterestedPartiesOpen}
                    setValue={setInterestedPartiesDropdownValue}
                    setItems={setInterestedParties}
                    onOpen={onInterestedPartiesOpen}
                    placeholder="Interested parties"
                    style={{ borderColor: 'lightgrey' }}
                    dropDownContainerStyle={{ borderColor: 'lightgrey' }}
                    multiple={true}
                  />
                </TouchableOpacity>
              </View>
            </>
          }
          <View style={[globalStyles.centeredContainer]}>
            <TouchableOpacity style={[globalStyles.saveBtn]} onPress={handleSubmit}>
              <Text style={globalStyles.loginText}>Create</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    )
  }
}
