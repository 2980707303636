import React, { useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TextInput,
  Button,
  TouchableOpacity,
  Pressable,
} from "react-native";
import AxiosContext from '../../components/AxiosContext';
import axios from 'axios';
import { GroupSettings, LifeOarLogEventTypes, LifeOarGroup, LifeOarTask, LifeOarTaskTypes } from "../../types/oarTypes";
import { FontAwesome5 } from '@expo/vector-icons';
import { NewGroupComponent } from '../../components/groups/NewGroupComponent';
import { globalStyles } from "../../constants/GlobalStyles";
import { AddTaskComponent } from "../../components/tasks/AddTaskComponent";

export default function AddTaskScreen({ navigation, route }: any) {
  const [isLoading, setIsLoading] = useState(true);
  const [newTaskData, setNewTaskData] = useState<LifeOarTask>();

  const createNewGroup = (newTaskData: LifeOarTask) => {
    axios.post('tasks', newTaskData)
      .then(function (response : any) {
        setNewTaskData(response.data);
      })
      .catch(function (error : any) {
        console.log(error.message);
      })
      .finally(function () {
        setIsLoading(false);
      });
  };

  return (
    <View style={[globalStyles.centeredContainer, { maxWidth: '95%' }]}>
      <AddTaskComponent route={route} navigation={navigation} ></AddTaskComponent>
    </View>
  );
}
