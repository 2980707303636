import React, { useState } from 'react';
import { View, Text, Pressable, TextInput } from 'react-native';
import { getEnabledJournalTypes, getJournalEntriesForMonth } from '../../services/Journal/journalService';
import moment from 'moment';
import { JournalEntryTypesDict } from '../../types/oarTypes';
import { useIsFocused } from '@react-navigation/native';
import { Avatar, Slider, Switch } from '@rneui/themed';
import { seaBlueColor } from '../../constants/Colors';
import { globalStyles } from '../../constants/GlobalStyles';
import { FontAwesome5 } from '@expo/vector-icons';


export default function JournalEntryScreen({ navigation, route }) {
  const [enabledJournalTypes, setEnabledJournalTypes] = useState([]);
  const [journalEntries, setJournalEntries] = useState({});
  const [dateToLoad] = useState(moment(route.params.date));
  const isFocused = useIsFocused();

  React.useEffect(() => {
    
    getEnabledJournalTypes().then((results) => {
      if (results._id != undefined && results._id) {
        var enabledJournalTypes = Object.entries(results.journalSettings)
          .filter(([key, value]) => value == true)
          .map(([key, value]) => { return key; });
        setEnabledJournalTypes(enabledJournalTypes);
        const initialScores = {};
        enabledJournalTypes.forEach(type => {
          initialScores[type] = type;
        });
        //setJournalScores(initialScores);
      }
    })

    getJournalEntriesForMonth(dateToLoad.year(), dateToLoad.week()).then((results) => {

    });
  }, [navigation, route, isFocused]);

  const toggleIncludeGroupTasksSwitch = (value: boolean) => {
    // setIncludeGroupTasks(value)
    // loadTaskData(value)
  }

  return (
    <View>
      {enabledJournalTypes.map((journalTypeValue, journalTypeIndex) => {
        const entryType = JournalEntryTypesDict.types[journalTypeValue].name;
        const controlType = JournalEntryTypesDict.types[journalTypeValue].controlType;
        return (
          <View style={globalStyles.container} key={journalTypeValue}>
            {controlType === 'boolean' &&
              <>
                <View style={{ ...globalStyles.container, width: "90%", flex: 2, flexDirection: 'row', justifyContent: "space-between", paddingBottom: 10 }}>
                  <Text style={globalStyles.agendaItemTitle}>{entryType}</Text>
                  <Switch color={seaBlueColor} style={{ width: 400 }}
                    onValueChange={(value) => {
                      setJournalEntries(prevEntries => ({
                        ...prevEntries,
                        [journalTypeValue]: value
                      }));
                    }} />
                </View>
              </>
            }
            {
              controlType === 'scale' &&
              <>
                <View style={{ ...globalStyles.container, flex: 2, width: "90%", flexDirection: 'row', justifyContent: "space-between", paddingBottom: 10, paddingTop: 10 }}>
                  <Text style={{ ...globalStyles.agendaItemTitle }}>{entryType}</Text>
                  <View style={{ ...globalStyles.container, flexDirection: 'row', justifyContent: "flex-end", alignSelf: "stretch" }}>
                    <Slider
                      allowTouchTrack
                      trackStyle={{ height: 10, backgroundColor: 'transparent' }}
                      thumbStyle={{ height: 20, width: 20, backgroundColor: seaBlueColor }}
                      style={{ width: 200, height: 40 }}
                      minimumValue={0}
                      maximumValue={10}
                      step={1}
                      onValueChange={(value) => {
                        setJournalEntries(prevEntries => ({
                          ...prevEntries,
                          [journalTypeValue]: value
                        }));
                      }}

                    />
                  </View>
                </View>
              </>
            }
            {controlType === 'numeric' && <Text>{entryType} numeric</Text>}
          </View>
        );
      })}

    </View>
  );
};
